var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"align":"end","justify":"space-between","no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('GoBack'),_c('p',{staticClass:"header-text"},[_vm._v(" "+_vm._s(_vm._f("formatAssignmentTitle")(_vm.assignmentData.assignment.title,_vm.assignmentData.assignment.short_title))+" ")]),_c('p',{staticClass:"lightCarbon--text font-weight-regular caption"},[_vm._v(" "+_vm._s(_vm._f("formatCourses")(_vm.assignmentData.assignment.courses))+" ")])],1),_c('v-col',{class:{ 'mt-3': _vm.$vuetify.breakpoint.mdAndDown },attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"px-14 button-start-checking",attrs:{"color":"primary","depressed":"","min-height":"55","min-width":"60","to":{
          name: 'StartChecking',
          query: {
            assignmentId: _vm.assignmentId,
          },
        }}},[_vm._v(" Start Checking ")]),_c('p',{staticClass:"text-center text--secondary font-weight-regular caption"},[_vm._v(" Start checking this assignment ")])],1)],1),_c('v-divider',{staticClass:"mt-2 mb-4"}),_c('v-row',{staticClass:"my-6 mb-16",attrs:{"dense":""}},_vm._l((_vm.cards),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12","lg":"3","md":"6"}},[_c('AnalyticsCard',{attrs:{"body":item.body,"color":item.color,"title":item.title}})],1)}),1),_c('div',{staticClass:"my-4"},[_c('ViewAssignmentSearch',{attrs:{"get-assignments-data":_vm.getAssignmentsData,"reset":_vm.resetPageNo}})],1),_c('v-data-table',{attrs:{"custom-sort":() => _vm.viewAssignments.data.data,"headers":_vm.headers,"hide-default-footer":"","items":_vm.viewAssignments.data.data,"loading":_vm.listLoading,"multi-sort":"","sort-by":_vm.viewAssignments.sort.fields,"sort-desc":_vm.viewAssignments.sort.desc},on:{"update:options":_vm.handleSort},scopedSlots:_vm._u([{key:`header.action`,fn:function(){return [_c('span',{staticClass:"subtitle-2 lightCarbon--text"},[_vm._v(" "+_vm._s(_vm.totalEntries)+" ")])]},proxy:true},{key:`item.user.full_name`,fn:function({ item }){return [_c('UserNameAndAvatar',{attrs:{"full-name":item.user.full_name,"url":item.user.photo}})]}},{key:`item.submitted_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.submitted_at ? _vm.moment(item.submitted_at).format("ll") : "-")+" ")]}},{key:`item.status`,fn:function({ item }){return [(!item.status)?_c('span',{staticClass:"red--text"},[_vm._v(" Not Reviewed ")]):(item.status === 'plagiarised')?_c('span',{staticClass:"red--text"},[_vm._v(" Plagiarised ")]):(
          item.status === 'not_reviewed' || item.status === 'under_review'
        )?_c('span',{staticClass:"orange--text"},[_vm._v(" Pending ")]):(item.status === 'accepted')?_c('span',{staticClass:"green--text"},[_vm._v(" Accepted ")]):(item.status === 'rejected')?_c('span',{staticClass:"red--text"},[_vm._v(" Rejected ")]):_vm._e()]}},{key:`item.review`,fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(item.review ? item.review.professor : "-")+" ")])]}},{key:`item.action`,fn:function({ item }){return [_c('v-btn',{staticClass:"pr-0 pr-md-2",attrs:{"color":"primary","small":"","text":"","to":{
          name: 'ViewSubmission',
          params: { submissionId: item.uuid },
        }}},[_vm._v(" VIEW SUBMISSION ")])]}},{key:"footer",fn:function(){return [_c('Paginator',{attrs:{"page":_vm.viewAssignments.page,"total":_vm.viewAssignments.data.total},on:{"change":_vm.handlePagination}})]},proxy:true}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }